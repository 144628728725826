import React from 'react'

import Contact from './Contact'

function Job() {
    return (
        <div>
           <Contact section="lavora_con_noi" />
        </div>
    )
}

export default Job
